import { useState, useEffect, useRef } from "react";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import useNow from "./useNow";

const EventSource = NativeEventSource || EventSourcePolyfill;
const api = process.env.REACT_APP_SSE || "https://api.barberconnect.fr";

export const Status = {
  DONE: "DONE",
  DELETED: "DELETED",
  CANCELED: "CANCELED",
  STARTED: "STARTED",
  WAITING: "WAITING",
  ABSENT: "ABSENT",
  ERROR: "404",
};

function fetchTicket(slug) {
  return fetch(`/api/v2/ticket/${slug}?include=location`)
    .then((resp) => resp.json())
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function useTicket(slug) {
  const now = useNow();
  const [ticketInfo, setTicketInfo] = useState(null);
  const [currentLocationId, setLocation] = useState(null);
  const [ticket, setTicket] = useState(null);
  const registerSse = useRef(null);

  // fetch ticket on slug change slug
  useEffect(() => {
    fetchTicket(slug)
      .then(setTicketInfo)
      .catch(() => setTicket({ status: "404" }));
  }, [slug]);

  // update ticket if new info or clock
  useEffect(() => {
    if (ticketInfo && now) {
      setTicket(loadTicket(ticketInfo));
    }
  }, [ticketInfo, now]);

  // listen for refresh event
  useEffect(() => {
    function onSseEvent(event) {
      let data = event.data;
      try {
        let json = JSON.parse(data);
        switch (json.type) {
          case "ping":
            break;
          case "sync":
            fetchTicket(slug)
              .then((info) => {
                setTicketInfo(info);
              })
              .catch(() => setTicket({ status: "404" }));
            break;
          default:
            break;
        }
      } catch (err) {
        console.error("could no parsse json");
        console.error(err);
      }
    }

    if (ticketInfo === null) {
      return;
    }

    if (currentLocationId !== ticketInfo.ticket.locationId) {
      let locationId = ticketInfo.ticket.locationId;
      if (registerSse.current) {
        registerSse.current.close();
      }
      registerSse.current = new EventSource(
        `${api}/api/v2/location/events?token=${locationId}`
      );
      registerSse.current.onmessage = onSseEvent;
      registerSse.current.onerror = console.error;
      registerSse.current.onopen = () => {
        setLocation(locationId);
      };
    }
  }, [currentLocationId, ticketInfo, registerSse, slug]);

  return ticket;
}

function loadTicket(body) {
  let ticket = body.ticket;
  let ticketExpected = new Date(body.expectedAt);

  let ticketRdv = new Date(ticket.firstExpectedTime);
  const today = ticketExpected.toLocaleDateString("fr", {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
  });
  const waitingTime = ticketExpected.toLocaleTimeString("fr", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const minTime = ticketRdv.toLocaleTimeString("fr", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const maxTime = new Date(
    ticketExpected.getTime() + 20 * 60 * 1000
  ).toLocaleTimeString("fr", {
    hour: "2-digit",
    minute: "2-digit",
  });

  let info = {
    ...body.ticket,
    queueInfo: body.queueInfo,
    location: body.location,
    date: today,
    expectedAt: body.expectedAt,
    isDone: body.ticket.doneTime !== null,
    userPosition: body.queuePosition,
    waitingList: body.queueLen,
    waitingTime,
    minTime,
    maxTime,
    status:
      body.ticket.doneTime !== null
        ? Status.DONE
        : body.ticket.deletedTime !== null
        ? Status.DELETED
        : body.ticket.canceledTime !== null
        ? Status.CANCELED
        : body.ticket.startedTime !== null
        ? Status.STARTED
        : body.ticket.doctorId == null
        ? Status.ABSENT
        : Status.WAITING,
  };
  return info;
}
