import React, { useState } from "react";

import CatalogItem from "./components/CatalogItem";

import Header from "../components/Header";
import Footer from "../components/Footer";
import LocationHeader from "../components/LocationHeader";
import InfoBar from "../components/InfoBar";
import MapHeader from "./components/MapHeader";
import Carousel from "./components/Carousel";
import { ButtonOutlineYellow } from "./components/Button";
import { Outter } from "./components/Outter";
import { ModalContainer, Modal } from "./components/Modal";
import { ReactComponent as OnboardingAlertSVG } from "./assets/onboarding-alert.svg";
import { ReactComponent as OnboardingQrSVG } from "./assets/onboarding-qr.svg";
import { ReactComponent as OnboardingTimeSVG } from "./assets/onboarding-time.svg";

const Prestations = ({ location, onPrestation, queueLines }) => {
  const [stepper, setStepper] = useState(0);
  let [selected, setSelected] = useState(null);
  const [modal, setModal] = useState(false);
  console.log(queueLines.map(q=>q.status));
  queueLines = queueLines.filter((q) => q.status !== "DISABLED");

  const isOpen = queueLines.some((e) => e.status !== "STOPED");
  const isPaused = queueLines.every((e) => e.status === "PAUSED" || e.status === "STOPED");


  function onValidate() {
    if (selected) {
      onPrestation(selected);
    }
  }

  function getModalContent() {
    if (stepper === 2) {
      return (
        <>
          <div className="modal-title">
            <span>Bienvenue dans votre</span>
            <em>salle d’attente virtuelle</em>
          </div>
          <div className="modal-content">
            <OnboardingAlertSVG />

            <div>
              <p>Votre tour approche ! </p>
              <p>Vous recevez un SMS avant votre passage.</p>
            </div>
          </div>
          <button
            className="done-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setStepper(0);
              setModal(false);
            }}
          >
            J’ai compris
          </button>
        </>
      );
    }
    if (stepper === 1) {
      return (
        <>
          <div className="modal-title">
            <span>Bienvenue dans votre</span>
            <em>salle d’attente virtuelle</em>
          </div>
          <div className="modal-content">
            <OnboardingQrSVG />
            <p>Choisissez votre créneau et réservez votre place ! </p>
            <p>
              Votre horaire et position dans la file sont mis à jour en temps
              réel.
            </p>
          </div>
          <button
            className="next-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setStepper(2);
            }}
          >
            Suivant
          </button>
        </>
      );
    }
    return (
      <>
        <div className="modal-content">
          <div className="modal-title">
            <span>Bienvenue dans votre</span>
            <em>salle d’attente virtuelle</em>
          </div>

          <div className="modal-content">
            <OnboardingTimeSVG />
            <p>
              <em>Pas besoin de vous déplacez !</em>
            </p>
            <p>
              <em>Consultez l'attente en temps réel</em> et inscrivez vous dans
              la file !
            </p>
          </div>
        </div>
        <button
          className="next-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setStepper(1);
          }}
        >
          Suivant
        </button>
      </>
    );
  }

  return (
    <Outter>
      <Header />
      {location.id === 30 &&
        <InfoBar
          onClick={() => {
            window.location.href = 'https://carden.app/telechargement';
          }}
        />
      }
      
      <MapHeader location={location} />

      <div className="content">
        <LocationHeader location={location} isOpen={isOpen} isPaused={isPaused}/>
        <Carousel queueLines={queueLines} />
        <h2>Bonjour, faites votre sélection</h2>

        <div
          style={{
            marginBottom: "3rem",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {location.catalog
            .filter((v) => v.status === "AVAILABLE" || v.status === "ENABLED")
            .map((p) => (
              <CatalogItem
                key={p.id}
                prestation={p}
                onClick={() => setSelected(p)}
                checked={selected && p.id === selected.id}
              />
            ))}
        </div>
        <ButtonOutlineYellow
          onClick={onValidate}
          disabled={!isOpen || isPaused || !selected}
        >
          Prendre un ticket
        </ButtonOutlineYellow>
      </div>
      <Footer />
      <ModalContainer
        isVisible={modal}
        onClick={() => {
          setModal(false);
        }}
      >
        <Modal>{getModalContent()}</Modal>
      </ModalContainer>
    </Outter>
  );
};

export default Prestations;
