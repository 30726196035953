import React, { useState } from "react";
import styled from "styled-components/macro";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Modal, ModalContainer } from "./components/Modal";
import { Outter } from "./components/Outter";
import PathTime from "./components/PathTime";

import { ReactComponent as ModalTimeSVG } from "./assets/modal-time.svg";
import { useClient } from "../../hooks/useClient";

const Avatar = styled.img`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const QueueHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
  margin-left: 18px;

  span {
    font-size: 17px;
    font-weight: 700;
    color: #37474f;
  }
`;

const QueueLayout = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const QueueCardLayout = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: ${(props) => (props.disabled ? null : "pointer")};
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  row-gap: 0px;
  justify-items: stretch;
  align-items: center;
  box-shadow: 3px 5px 7px rgba(205, 205, 205, 0.8);

  &:active {
    box-shadow: inset 3px 5px 7px rgba(205, 205, 205, 0.8);
  }

  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 0.3rem;

  ${Avatar} {
    filter: ${(props) => (props.disabled ? "grayscale(100%)" : null)};
  }

  h4 {
    margin: 0;
    padding: 0;

    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: ${(props) => (props.disabled ? "#ccc" : "#37474f")};
  }

  .availability {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 118.69%;

    display: flex;
    align-items: center;

    color: ${(props) =>
    props.disabled ? "#979797" : props.active ? "#23bbac" : "#049BE5"};
    ::before {
      content: "";
      width: 12px;
      height: 12px;
      background-color: ${(props) =>
    props.disabled ? "#979797" : props.active ? "#23bbac" : "#049BE5"};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .time {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    min-width: 3em;

    display: flex;
    position: relative;
    margin-left: -15px;
    align-items: center;

    color: #37474f;
  }
`;
const QueueCard = ({ queue, onClick }) => {
  const disabled = queue.status !== "AVAILABLE";
  const active = queue.tickets.length === 0;

  const queueTime = queue.time || new Date();
  let timeString = `${queueTime
    .getHours()
    .toString()
    .padStart(2, "0")}h${queueTime.getMinutes().toString().padStart(2, "0")}`;

  return (
    <QueueCardLayout
      disabled={disabled}
      active={active}
      onClick={() => !disabled && onClick()}
    >
      <Avatar src={queue.avatar} />
      <div
        style={{
          flex: 1,
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          minWidth: "12rem",
        }}
      >
        <h4 className="name">{queue.name}</h4>
        <div className="availability">
          {disabled
            ? "Indisponible"
            : active
              ? "Disponible immédiatement"
              : queue.tickets.length > 1
                ? `${queue.tickets.length} personnes en attente`
                : `${queue.tickets.length} personne en attente`}
        </div>
      </div>
      <div className="time">{!disabled ? timeString : null}</div>
    </QueueCardLayout>
  );
};
const Queueline = ({
  queueLines,
  location,
  pathTimeM,
  onPathTimeM,
  onQueue,
  vehicule,
  setVehicule,
}) => {
  const [modalTimeInfo, setModalTimeInfo] = useState(null);
  const [selected, setSelected] = useState(null);
  const { client } = useClient();
  const displayOnboarding = client && client.tickets.length === 0;

  queueLines = queueLines.filter((q) => q.status !== "DISABLED");
  queueLines.sort((a, b) => {
    if (a.status === "AVAILABLE" && b.status === "AVAILABLE") {
      if (a.tickets.length < b.tickets.length) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.status === "AVAILABLE" && b.status !== "AVAILABLE") {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <Outter>
      <Header />

      <div className="content">

        
        {location.id !== 31 && (
          <PathTime
            location={location}
            setTime={onPathTimeM}
            time={pathTimeM}
            vehicule={vehicule}
            setVehicule={setVehicule}
          />
        )}

        <QueueHeader>
          <span>Nous vous attendons pour :</span>
        </QueueHeader>

        <QueueLayout>
          {queueLines.map((q) => (
            <QueueCard
              key={q.id}
              queue={q}
              onClick={() => {
                if (displayOnboarding) {
                  setSelected(q);
                  setModalTimeInfo(true);
                } else {
                  onQueue(q);
                }
              }}
            ></QueueCard>
          ))}
        </QueueLayout>
      </div>
      <Footer />
      <ModalContainer
        isVisible={modalTimeInfo}
        onClick={() => {
          setModalTimeInfo(false);
        }}
      >
        <Modal>
          <div className="modal-content">
            <ModalTimeSVG />
            <div style={{ fontSize: 14 }}>
              <p style={{ textAlign: "justify" }}>
                Votre horaire de passage est une estimation, susceptible de
                varier en fonction des retards ou imprévus.
              </p>
              <p style={{ textAlign: "justify" }}>
                Il sera mis à jour en temps réel sur votre ticket.
              </p>
              <p style={{ textAlign: "justify" }}>
                Pour plus de confort, nous avons également déterminé :
              </p>
              <ul>
                <li>
                  Un horaire minimum : vous ne passerez pas avant cette heure.
                </li>
                <li>
                  Un horaire maximum : vous ne devriez pas passer après cet
                  horaire.
                </li>
              </ul>
            </div>
          </div>
          <button
            className="done-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalTimeInfo(false);
              onQueue(selected);
            }}
          >
            J’ai compris
          </button>
        </Modal>
      </ModalContainer>
    </Outter>
  );
};

export default Queueline;
