import styled from "styled-components";
import PathTimeModule from "./PathTimeModule";

import { ReactComponent as LocationSVG } from "../../assets/location.svg";

const PathTimeLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #fff;

  .logo {
    height: 50px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  svg {
    height: 50px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  span {
    font-size: 17px;
    font-weight: 700;
    color: #37474f;
  }
`;

const Row = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;

  font-size: 15px;
  font-weight: 700;
  color: #37474f;
  justify-content: space-around;
  align-items: center;
  span {
  }
  a {
    font-size: 11px;
    font-weight: 400;
  }
`;

const CardenPromo = styled.div`
text-align:justify;
padding:1rem;
background: #0073FF;
color: #FFF;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #FFF;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;

button {
  margin:auto;
  padding:1rem;
    border-radius: 20px;
    border: 0.615px solid #FFF;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    line-height: 12.308px; /* 123.077% */
    background:none
  }

`;

const PathTime = ({ location, setTime, time, vehicule, setVehicule }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;
  return (
    <PathTimeLayout>
      <Header>
        {location.avatar ? (
          <img src={location.avatar} alt="logo" className="logo" />
        ) : (
          <LocationSVG />
        )}
        <span>
          Dans combien de temps <br /> pouvez-vous être sur place ?
        </span>
      </Header>

      {location.id === 30 &&
      <CardenPromo>
          <p>
            La réservation en ligne sur Barber Connect
            est désormais limitée à la prochaine heure.
          </p>
          <p>
            Si vous souhaitez réserver votre place plus tard, merci de télécharger l’application mobile Carden.
          </p>
           <Row><button>Télécharger l’application</button></Row> 
        </CardenPromo>
      }

      <Row>
        <span>Je peux être là dans : </span>
        <a href={geoUrl}>Calculer mon temps de trajet</a>
      </Row>

      <PathTimeModule
        location={location}
        setTime={setTime}
        time={time}
        vehicule={vehicule}
        setVehicule={setVehicule}
      />
    </PathTimeLayout>
  );
};

export default PathTime;
