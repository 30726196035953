import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoSVG } from "../assets/logo.svg";
import Button from "./Born/components/Buttons";
import Input, { InputRow } from "./Born/components/Input";
import Footer from "./components/Footer";

const Layout = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  background: #312c2c;
  color: #fff;

  h1 {
    font-size: 64px;
    font-weight: 300;
    font-size: 64px;
    line-height: 75px;
    display: flex;
    flex-direction: column;
    align-items: justify;
    justify-content: justify;
    margin-bottom: 2rem;
    text-align: justify;
  }
  em {
    font-style: normal;
    font-weight: bolder;
  }
  .code {
    font-style: normal;
    font-weight: 300;
    font-size: min(20vw, 40px);
    line-height: 115px;
    text-align: center;
    color: #ffcc09;
    padding: 1rem;

    flex: 1;
  }
  .qrcode-container {
    background-color: white;
    padding: 1rem;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
`;

export function DeviceLoginManual({ onLogin }) {
  const [apiKey, setApiKey] = useState("");

  return (
    <Layout>
      <LogoSVG />
      <div className="col">
        <div className="col">
          <h2>Merci de saisir ce code sur la tablette</h2>
          <InputRow>
            <Input
              id="api-key"
              key="api-key"
              autoFocus
              autoComplete="off"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </InputRow>
          <p></p>
          <p>En cas de diffuculté, appelez nous au 06 61 66 45 22</p>
          <Button
            onClick={() => {
              if (apiKey) {
                fetch("/api/v2/device/login-with-key", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ apiKey }),
                });
              }
            }}
          >
            Valider
          </Button>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export default DeviceLoginManual;
