import React from "react";
import styled from "styled-components";

import { ReactComponent as CardenSVG } from "../assets/carden.svg";

const InfoBarLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0073FF;
  padding: 1rem;
  cursor: pointer;

  .info-bar-head {
    height: 100%;
    svg {
      height: 100%;
    }
  }

  .info-bar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: white;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .right {
    transform: rotate(-45deg);
  }
  button {
    border-radius: 14.769px;
    border: 0.615px solid #FFF;
    width: 78px;
    height: 24px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    line-height: 12.308px; /* 123.077% */
    width: 67.438px;
    background:none
  }
`;

const InfoBar = ({ onClick }) => {
  return (
    <InfoBarLayout onClick={onClick}>
      <div className="info-bar-head">
        <CardenSVG height={39} width={39} />
      </div>
      <div className="info-bar-content">
        <div>
          L’application est maintenant
          disponible ! Passage prioritaire,
          points de fidélité, avantages....

        </div>
      </div>
      <div className="info-bar-tail">
        <button>Télécharger</button>
      </div>
    </InfoBarLayout>
  );
};

export default InfoBar;
