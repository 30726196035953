import { useState, useEffect, useRef } from 'react'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'

const EventSource = NativeEventSource || EventSourcePolyfill

/**
 *
 * @param {string|int} slug location slug or id
 * @returns {[Location, queueLines, boolean]}
 */
function useLocation(slug) {
  const [location, setLocation] = useState(null)
  const [queueLines, setqueueLines] = useState(null)
  const [notFound, setNotFound] = useState(null)

  const queueRef = useRef(null)
  const locationRef = useRef(null)

  function onSseEvent(event) {
    let data = event.data
    try {
      let json = JSON.parse(data)
      switch (json.type) {
        case 'ping':
          break
        case 'sync':
          queueRef.current = json.queueLines.filter((q) => q.doctorId !== null)
          const closingTimes = queueRef.current
            .filter((q) => q.worker)
            .filter((q) => q.worker.status === 'AVAILABLE')
            .map((q) => {
              let end = new Date()
              end.setHours(q.worker.endHour, q.worker.endMinute)
              return end.getTime()
            })

          if (closingTimes.length > 0) {
            const maxTime = Math.max(...closingTimes)
            const closeAt = new Date()
            closeAt.setTime(maxTime)
            const newLocation = JSON.parse(JSON.stringify(locationRef.current))
            newLocation.closeAt = closeAt

            locationRef.current = newLocation
            setLocation(locationRef.current)
          }
          setqueueLines(queueRef.current)
          break
        case 'updateWorkerTime':
          var queues = queueRef.current
          if (queues == null) {
            return
          }

          //deep copy
          var newQueue = JSON.parse(JSON.stringify(queues))
          var idx = newQueue.findIndex((q) => q.id === json.workerId)
          if (idx !== -1) {
            newQueue[idx].worker.endHour = json.endHour
            newQueue[idx].worker.endMinute = json.endMinute

            const closingTimes = newQueue
              .filter((q) => q.worker)
              .filter((q) => q.worker.status === 'AVAILABLE')
              .map((q) => {
                let end = new Date()
                end.setHours(q.worker.endHour, q.worker.endMinute)
                return end.getTime()
              })

            if (closingTimes.length > 0) {
              const maxTime = Math.max(...closingTimes)
              const closeAt = new Date()
              closeAt.setTime(maxTime)
              const newLocation = JSON.parse(JSON.stringify(locationRef.current))
              newLocation.closeAt = closeAt

              locationRef.current = newLocation
              setLocation(locationRef.current)
            }
            queueRef.current = newQueue
            setqueueLines(queueRef.current)
          }
          break
        default:
          break
      }
    } catch (err) {
      console.error('SSE error', err)
    }
  }

  function slugToId(slug) {
    if (slug === '17th-barbers') {
      return 31
    }
    if (slug === 'barber-avenue') {
      return 30
    }
    if (
      slug === 'gentlemen' ||
      slug === 'gentlemens' ||
      slug === 'gentleman' ||
      slug === 'gentleman-barber' ||
      slug === 'gentlemanbarber'
    ) {
      return 32
    }
    if (slug === '6tj-barber') {
      return 130
    }
    if (slug === 'the-barber-station') {
      return 48
    }
    if (slug === 'barbenoire') {
      return 27
    }
    if (slug === 'barber-district') {
      return 149
    }
    if (slug === 'old-barber') {
      return 150
    }
    if (slug === 'le-binks-barbershop') {
      return 151
    }
    if (slug === 'demo') {
      return 24
    }
    if (slug === 'fitcare/bezons' || slug === 'fitcare-bezons') {
      return 213
    }
    if (slug === 'bulldog-barber') {
      return 245
    }
    if (slug === 'cher-monsieur-argenteuil') {
      return 245
    }
    if (slug === 'barber-infinity') {
      return 360
    }
    if (slug === 'fit-care-saint-ouen') {
      return 359
    }
    if (slug === 'madison-square-barber') {
      return 665
    }
    if (slug === 'barbercut2') {
      return 1145
    }
    if (slug === '235-cergy') {
      return 1206
    }
    if (slug === 'barberkong') {
      return 1267
    }
    if (slug === 'gentleman-groslay') {
      return 1268
    }
    if (slug === 'asbarber-lepecq') {
      return 3009
    }
    if (slug === 'labarbe-a-papa') {
      return 3069
    }
    if(slug === 'l-onglerie-paris-madeleine') {
      return 3589;
    }
    return parseInt(slug)
  }

  useEffect(() => {
    const api = process.env.REACT_APP_SSE || 'https://api.barberconnect.fr'
    var id = slugToId(slug)

    const closeAt = new Date()
    closeAt.setHours(19, 30)

    if (slug === 'demo') {
      closeAt.setHours(23)
      closeAt.setMinutes(42)
    }

    fetch(`/api/v2/location/${id}`)
      .then((data) => data.json())
      .then((resp) => {
        // TODO handle closeAt in backend

        const closingTimes = resp.queueLines
          .filter((q) => q.worker)
          .filter((q) => q.worker.status === 'AVAILABLE')
          .map((q) => {
            let end = new Date()
            end.setHours(q.worker.endHour, q.worker.endMinute)
            return end.getTime()
          })
        if (closingTimes.length > 0) {
          const maxTime = Math.max(...closingTimes)
          closeAt.setTime(maxTime)
        }

        locationRef.current = { ...resp.location, closeAt }
        queueRef.current = resp.queueLines.filter((w) => w.doctorId !== null)

        setLocation(locationRef.current)
        setqueueLines(queueRef.current)

        setNotFound(false)
        var registerSse = new EventSource(
          `${api}/api/v2/location/events?token=${resp.location.id}`,
        )
        registerSse.onmessage = onSseEvent
        registerSse.onerror = console.error
      })
      .catch((err) => {
        setNotFound(true)
        console.error(err)
      })
  }, [slug])

  return [location, queueLines, notFound]
}

export default useLocation
