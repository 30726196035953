import styled from "styled-components";
import { ReactComponent as ItineraireSVG } from "../assets/itineraire.svg";
import { PathTimeSelect, VehiculeSelect } from "./CustomSelect";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 162px;
  background-color: ${(props) => props.theme.main};
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 4px;
  align-items: center;
  svg {
    margin-left: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 8px;

  font-size: 15px;
  font-weight: 700;
  color: #fff;

  .destination {
    margin-bottom: -16px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: px;
  }

  span {
    font-size: 26px;
  }
`;

const PathTimeModule = ({ location, setTime, time, vehicule, setVehicule }) => {
  const name = location.name || "";
  const address =
    location.address + " " + location.zipCode + " " + location.city ||
    "Votre destination";
  const times = location.id === 30 ? [5, 15, 30, 60] : [5, 15, 30, 60, 90, 60 * 2, 60 * 2 + 30, 60 * 3];


  return (
    <Container>
      <ItineraireSVG />
      <Content>
        <Row>Ma position</Row>

        <Row className="center-row">
          <VehiculeSelect vehicule={vehicule} setVehicule={setVehicule} />
          <PathTimeSelect
            setTime={setTime}
            time={time}
            times={times}
            end={location.closeAt}
          />
        </Row>
        <Row className="destination">
          {name} <br />
          {address}
        </Row>
      </Content>
    </Container>
  );
};

export default PathTimeModule;
