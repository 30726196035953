import React, { useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import Button from "./components/Button";
import Input from "./components/Input";
import { Outter } from "./components/Outter";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { LocationheaderCard } from "../components/LocationHeader";

const Card = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  .sex {
    flex: 1;
    margin-right: 5px;
  }
  .age {
    margin-top: 30px;
  }
`;

const TicketFormTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    padding: 0;
    margin: 0;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;

    color: #37474f;
  }

  .subtitle {
    padding: 0;
    margin: 0;

    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 118.69%;

    margin-left: 0.6rem;
    margin-top: 0.3rem;

    color: #37474f;
  }
`;

const TicketFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 2rem;
    }
  }
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  height: 44px;
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  font-size: 15px;
  font-weight: 700;
`;

const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.img`
  border-radius: 50%;
  margin: 4px;
  width: 35px;
  height: 35px;
  margin-left: 10px;
`;

const TicketForm = ({
  prestationId,
  queueId,
  location,
  queueLines,
  pathTimeM,
}) => {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const prestation = prestationId || location.catalog[0].id;
  const queue = queueId || queueLines[0].doctorId;
  const currentQueue = queueLines.find((q) => q.id === queueId);
  const avatar = currentQueue?.avatar;
  const currentPrestation = location.catalog.find((q) => q.id === prestationId);

  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;

  function onTicket() {
    let expectedTime = queue.time || new Date();
    if (pathTimeM) {
      expectedTime.setTime(expectedTime.getTime() + pathTimeM * 60000);
    }
    if (currentQueue.time) {
      expectedTime = currentQueue.time;
    }

    if (location.id === 31) {
      expectedTime = new Date();
    }

    let ticketForm = {
      phone,
      name,
      age: null,
      sex: null,
      queueId: queue,
      locationId: location.id,
      tickettype: "CONSULTATION",
      services: [prestation],
      expectedTime,
      eticket: true,
    };
    setLoading(true);
    fetch("/api/v2/ticket", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticketForm),
    })
      .then((d) => d.json())
      .then((payload) => {
        const ticket = payload.payload;
        navigate(`/ticket-view/${ticket.slug}`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("on ticket error : ", err);
      });
  }

  if (prestationId === null || queueId === null) {
    navigate("/");
    return null;
  }

  return (
    <Outter>
      <Header />

      <TicketFormLayout>
        <Card>
          <LocationheaderCard location={location} />
        </Card>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onTicket();
          }}
        >
          <Card>
            <TicketFormTitle>
              <h2 className="title"> Votre séléction</h2>
              <Selection>
                <Avatar src={avatar} />
                <Row>{currentPrestation.name}</Row>
              </Selection>
            </TicketFormTitle>
          </Card>

          <Card>
            <TicketFormTitle>
              <h2 className="title"> Vos informations personnelles</h2>
            </TicketFormTitle>

            <Input
              type="tel"
              id="phone"
              name="phone"
              autoComplete="tel-local"
              pattern="[0-9]{10}"
              required
              placeholder="Téléphone mobile*"
              empty={phone === null || phone === ""}
              onChange={(e) => setPhone(e.target.value)}
            />
            <SimpleRow>
              <Input
                type="text"
                id="firstname"
                name="firstname"
                autoComplete="given-name"
                pattern=".{3,}"
                placeholder="Prénom*"
                required
                empty={name === null || name === ""}
                onChange={(e) => setName(e.target.value)}
              />
            </SimpleRow>
          </Card>

          <Card>
            <label style={{ marginTop: "2rem" }} required>
              <input
                type="checkbox"
                style={{ marginRight: "0.5rem" }}
                required
              />
              J’ai <a href={geoUrl}>vérifié mon temps de trajet</a> pour arriver
              à l’heure
            </label>
            <label style={{ marginTop: "2rem" }}>
              <input
                type="checkbox"
                style={{ marginRight: "0.5rem" }}
                required
              />
              J’accepte les{" "}
              <a href="https://barberconnect.fr/cgu">
                conditions générales d’utilisation
              </a>
              du service
            </label>
            <Button disabled={loading}>Confirmer</Button>
          </Card>
        </form>
      </TicketFormLayout>

      <Footer />
    </Outter>
  );
};

export default TicketForm;
