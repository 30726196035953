import React, { useState, useEffect } from "react";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import styled from "styled-components";
import { ReactComponent as LogoSVG } from "../assets/logo.svg";
import Footer from "./components/Footer";

const EventSource = NativeEventSource || EventSourcePolyfill;
const api = process.env.REACT_APP_SSE || "https://api.barberconnect.fr";
var InnerNonce = false;

const Layout = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  background: #312c2c;
  color: #fff;

  h1 {
    font-size: 64px;
    font-weight: 300;
    font-size: 64px;
    line-height: 75px;
    display: flex;
    flex-direction: column;
    align-items: justify;
    justify-content: justify;
    margin-bottom: 2rem;
    text-align: justify;
  }
  em {
    font-style: normal;
    font-weight: bolder;
  }
  .code {
    font-style: normal;
    font-weight: 300;
    font-size: min(20vw, 40px);
    line-height: 115px;
    text-align: center;
    color: #ffcc09;
    padding: 1rem;

    flex: 1;
  }
  .qrcode-container {
    background-color: white;
    padding: 1rem;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
`;

export function DeviceLoginSafe({ onLogin }) {
  const [nonce, setNonce] = useState("empty");

  useEffect(() => {
    function onSseEvent(event) {
      let data = event.data;
      if (data === "ping") {
        return;
      }

      if (!InnerNonce) {
        setNonce(data);
        InnerNonce = data;
        return;
      }
      fetch("/api/v2/device/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          locationId: parseInt(data, 10),
          nonce: InnerNonce,
        }),
      })
        .then((data) => data.json())
        .then((json) => {
          let apiKey = json.apiKey;
          if (apiKey && localStorage) {
            localStorage.setItem("api-key", apiKey);
          }
          return fetch("/api/v2/me");
        })
        .then((data) => {
          if (!data.ok) {
            throw new Error("need-login");
          }
          return data.json();
        })
        .then((json) => onLogin(json))
        .catch((err) => {
          console.error(err);
        });
    }
    var registerSse = new EventSource(`${api}/api/v2/device/register`, {
      withCredentials: true,
    });
    registerSse.onmessage = onSseEvent;
    registerSse.onerror = console.error;
    return () => {
      registerSse.close();
    };
  }, [onLogin]);

  if (nonce === "empty") {
    return (
      <Layout>
        <LogoSVG />
        <div className="col">
          <p>recuperation du code</p>
          <p>En cas de diffuculté, appelez nous au 06 61 66 45 22</p>
        </div>
        <Footer />
      </Layout>
    );
  }

  return (
    <Layout>
      <LogoSVG />
      <div className="col">
        <div className="col">
          <h2>Merci de saisir ce code sur la tablette</h2>
          <div className="code">{nonce}</div>
          <p>En cas de diffuculté, appelez nous au 06 61 66 45 22</p>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export default DeviceLoginSafe;
